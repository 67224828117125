import request from '@/utils/request'

// 查询稿件恢复申请列表
export function listResumeApply(query) {
  return request({
    url: '/community/resume/apply/list',
    method: 'get',
    params: query
  })
}

// 查询稿件恢复申请详细
export function getResumeApply(id) {
  return request({
    url: '/community/resume/apply/' + id,
    method: 'get'
  })
}

// 新增稿件恢复申请
export function addResumeApply(data) {
  return request({
    url: '/community/resume/apply',
    method: 'post',
    data: data
  })
}

// 修改稿件恢复申请
export function updateResumeApply(data) {
  return request({
    url: '/community/resume/apply',
    method: 'put',
    data: data
  })
}

// 删除稿件恢复申请
export function delResumeApply(id) {
  return request({
    url: '/community/resume/apply/' + id,
    method: 'delete'
  })
}
