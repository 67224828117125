<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="审核状态" prop="state">
        <a-select style="width: 100%" v-model="form.state" placeholder="请输入审核状态">
          <a-select-option v-for="(item, index) in this.customDict.ResumeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="申诉内容" prop="content">
        <!--        <editor v-model="form.content" />-->
        <a-input disabled v-model="form.content" placeholder="请输入内容" type="textarea" allow-clear/>
      </a-form-model-item>
<!--      <a-form-model-item label="图片" prop="img">
        <a-input v-model="form.img" placeholder="请输入内容" type="textarea" allow-clear/>
      </a-form-model-item>-->
      <a-form-model-item label="图片" prop="img">
        <a-upload disabled
          name="img"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="imgAvatarUpload">
          <img
            v-if="form.img"
            :src="form.img"
            alt="img"
            style="height: 102px; width: 102px; border-radius: 50%"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
<!--      <a-form-model-item label="动态id 外键community_article.id" prop="articleId">
        <a-input v-model="form.articleId" placeholder="请输入动态id 外键community_article.id"/>
      </a-form-model-item>-->
      <!--      <a-form-model-item label="退回记录id community_off_record.id" prop="offRecordId" >
              <a-input v-model="form.offRecordId" placeholder="请输入退回记录id community_off_record.id" />
            </a-form-model-item>
            <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
              <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark" >
              <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
            </a-form-model-item>
            <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
              <a-input v-model="form.version" placeholder="请输入乐观锁" />
            </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getResumeApply, addResumeApply, updateResumeApply} from '@/api/community/resumeApply'
import Editor from '@/components/Editor'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import { generateFilePath, uploadObject } from '@/api/tool/alioss'
export default {
  name: 'CreateForm',
  props: {},
  components: {
    Editor
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        state: null,

        content: null,

        img: null,

        articleId: null,

        offRecordId: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        state: [
          {required: true, message: '状态：1申请中2已通过3未通过不能为空', trigger: 'blur'}
        ],

        content: [
          {required: true, message: '申诉内容不能为空', trigger: 'blur'}
        ],

        articleId: [
          {required: true, message: '动态id 外键community_article.id不能为空', trigger: 'blur'}
        ],

        offRecordId: [
          {required: true, message: '退回记录id community_off_record.id不能为空', trigger: 'blur'}
        ],

        isDeleted: [
          {required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur'}
        ],

        version: [
          {required: true, message: '乐观锁不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        state: null,

        content: null,

        img: null,

        articleId: null,

        offRecordId: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getResumeApply(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateResumeApply(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addResumeApply(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },


// oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
// 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
// 上传用户头像覆盖默认的上传行为
    imgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'img'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'img', res)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
// oss 上传结束
  }
}
</script>
